import React, { useState } from 'react';
import MensajesIngles from './../lang/en-US.json';
import MensajesEspañol from './../lang/es-ES.json';
import { IntlProvider } from 'react-intl';

const langContext = React.createContext();

const LangProvider = ({ children }) => {
    const [mensajes, establecerMensajes] = useState(MensajesIngles);
    const [locale, establecerLocale] = useState('en-US');

    const establecerLenguaje = (lenguaje) => {
        switch (lenguaje) {
            case 'en-US':
                establecerMensajes(MensajesIngles);
                establecerLocale('en-US');
                break;
            case 'es-ES':
                establecerMensajes(MensajesEspañol);
                establecerLocale('es-ES');
                break;
            default:
                establecerMensajes(MensajesEspañol);
                establecerLocale('es-ES');
        }
    }

    return (
        <langContext.Provider value={{ establecerLenguaje: establecerLenguaje }}>
            <IntlProvider locale={locale} messages={mensajes}>
                {children}
            </IntlProvider>
        </langContext.Provider>
    );
}

export { LangProvider, langContext };