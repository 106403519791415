import React from 'react';
import perfil1 from '../images/perfil1.jpg'
import perfil2 from '../images/perfil2.jpg'
import equipo from '../images/equipo.jpg'
import linkedin1 from '../icons/linkedin1.svg'
import flecha from '../icons/flechita.svg'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
gsap.registerPlugin(ScrollTrigger)

const Equipo = () => {

    const parentRef = useRef(null);
    const movingElementRef = useRef(null);

    useEffect(() => {
        const parentElement = parentRef.current;
        const movingElement = movingElementRef.current;

        ScrollTrigger.create({
            trigger: parentElement,
            start: 'top 29%',
            end: 'bottom 47.7%',
            scrub: true,
            //markers: true,
            onUpdate: (self) => {
                const progress = self.progress;
                const parentHeight = parentElement.offsetHeight;
                const elementHeight = movingElement.offsetHeight;
                const maxTranslation = parentHeight - elementHeight;

                gsap.set(movingElement, { y: maxTranslation * progress * 1 });
            },
        });
    }, []);


    return <>
        <section className='equipo' id='equipo'>
            <div className='container'>
                <div className='part1' ref={parentRef}>
                    <div className='title' ref={movingElementRef}>
                        <h2 className='mensajeIngles'><FormattedMessage id="equipo_title" /></h2>
                        <h2 className='ocultar mensajeEspanol'><FormattedMessage id="equipo_title" /></h2>
                        <p>We find the perfect balance between media and strategy, enabling our meticulously crafted brands to genuinely resonate with people. In our studio, each piece of work is designed to solve a problem, and every innovative action is driven by purpose</p>
                    </div>
                </div>
                <div className='part2'>
                    <div className='listEquipo'>
                        <div className='itemEquipo'>
                            <div className='perfil'>
                                <img src={perfil1} alt="perfil1" />
                            </div>
                            <div className='datos'>
                                <div className='datos1'>
                                    <img src={perfil1} alt="perfil1" />
                                    <div className='datos2'>
                                        <h3>Alonso Montoya</h3>
                                        <p><FormattedMessage id="equipo_cargo1" /></p>
                                        <a href='#'><img src={linkedin1} alt="perfil1" /></a>
                                    </div>
                                </div>
                                <div className='desplegable'>
                                    <p>Lorem ipsum dolor sit amet consectetur. Diam id auctor hendrerit neque lacus fringilla congue aliquam. Urna nisi pulvinar lorem sollicitudin nisi risus eu est facilisis.</p>
                                </div>
                            </div>
                            <div className='flecha'>
                                <img src={flecha} alt="flecha" />
                            </div>
                        </div>
                        <div className='itemEquipo'>
                            <div className='perfil'>
                                <img src={perfil2} alt="perfil1" />
                            </div>
                            <div className='datos'>
                                <div className='datos1'>
                                    <img src={perfil2} alt="perfil1" />
                                    <div className='datos2'>
                                        <h3>Francesca Noriega</h3>
                                        <p><FormattedMessage id="equipo_cargo2" /></p>
                                        <a href='#'><img src={linkedin1} alt="perfil2" /></a>
                                    </div>
                                </div>
                                <div className='desplegable'>
                                    <p>Lorem ipsum dolor sit amet consectetur. Diam id auctor hendrerit neque lacus fringilla congue aliquam. Urna nisi pulvinar lorem sollicitudin nisi risus eu est facilisis.</p>
                                </div>
                            </div>
                            <div className='flecha'>
                                <img src={flecha} alt="flecha" />
                            </div>
                        </div>
                        <div className='itemEquipo'>
                            <div className='perfil'>
                                <img src={perfil1} alt="perfil1" />
                            </div>
                            <div className='datos'>
                                <div className='datos1'>
                                    <img src={perfil1} alt="perfil1" />
                                    <div className='datos2'>
                                        <h3>Alonso Montoya</h3>
                                        <p><FormattedMessage id="equipo_cargo1" /></p>
                                        <a href='#'><img src={linkedin1} alt="perfil1" /></a>
                                    </div>
                                </div>
                                <div className='desplegable'>
                                    <p>Lorem ipsum dolor sit amet consectetur. Diam id auctor hendrerit neque lacus fringilla congue aliquam. Urna nisi pulvinar lorem sollicitudin nisi risus eu est facilisis.</p>
                                </div>
                            </div>
                            <div className='flecha'>
                                <img src={flecha} alt="flecha" />
                            </div>
                        </div>
                    </div>
                    <div className='fondo'>
                        <img src={equipo} alt="equipo" />
                    </div>
                </div>
                <div className='part3'>
                    <p>We find the perfect balance between media and strategy, enabling our meticulously crafted brands to genuinely resonate with people. In our studio, each piece of work is designed to solve a problem, and every innovative action is driven by purpose</p>
                </div>
            </div>
        </section>
    </>
}

export default Equipo;