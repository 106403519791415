import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import lamar from '../images/lamar.webp';
import wedding from '../images/wedding.webp';
import nerd from '../images/nerd2.jpg';
import vet from '../images/vetsanmarcos.webp';
import chavin from '../images/chavin.webp';
import lamarWeb from '../images/lamarWeb.webp';
import provetsur from '../images/provetsur.webp';
import xinxingWeb from '../images/xinxingWeb2.jpg';
import touch from '../icons/touch.svg';
import { FormattedMessage } from 'react-intl';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';


const Portafolio = () => {
    function change(e) {
        var nameCategory = e.target.getAttribute("category");
        var listSwiper = document.querySelectorAll(".portafolio .swiper")
        var itemsPortafolio = document.querySelectorAll(".itemsPortafolio .itemPortafolio")
        var listMenu = document.querySelectorAll(".portafolio .categoria ul li")

        for (let i = 0; i < listMenu.length; i++) {
            listMenu[i].classList.remove("active");
        }

        for (let i = 0; i < listMenu.length; i++) {
            if (nameCategory === listMenu[i].getAttribute("category")) {
                listMenu[i].classList.add("active");
            }
        }

        for (let i = 0; i < listSwiper.length; i++) {
            listSwiper[i].classList.add("ocultar");
            listSwiper[i].classList.remove("mostrar");
        }

        for (let i = 0; i < listSwiper.length; i++) {
            if (nameCategory === listSwiper[i].getAttribute("category")) {
                listSwiper[i].classList.add("mostrar");
                listSwiper[i].classList.remove("ocultar");
            }
        }
    }

    setTimeout(() => {
        var items = document.querySelectorAll('.itemPortafolio');
        for (var i = 0; i < items.length; i++) {
            //items[i].style.display = 'none';
            items[i].classList.add('ocultar');
        }

        const categoryMain = document.querySelector('.categoria li.active').getAttribute("category");
        if (categoryMain === "Todos") {
            for (var i = 0; i < items.length; i++) {
                if (i < 3) {
                    //items[i].style.display = 'block';
                    items[i].classList.remove('ocultar');
                } else {
                    //items[i].style.display = 'none';
                    items[i].classList.add('ocultar');
                }
            }
        }

        var itemsConCat = document.querySelectorAll(`.itemPortafolio.modulo-mas[category="${categoryMain}"]`);
        for (var i = 0; i < itemsConCat.length; i++) {
            if (i < 3) {
                //itemsConCat[i].style.display = 'block';
                itemsConCat[i].classList.remove('ocultar');
            } else {
                //itemsConCat[i].style.display = 'none';
                itemsConCat[i].classList.add('ocultar');
            }
        }

        const enlaces = document.querySelectorAll('.categoria li');
        enlaces.forEach((elemento) => {
            elemento.addEventListener('click', (evento) => {
                evento.preventDefault();
                enlaces.forEach((enlace) => enlace.classList.remove('active'));
                evento.target.classList.add('active');
                var categoria = evento.target.getAttribute("category");
                console.log(categoria);
                for (var i = 0; i < items.length; i++) {
                    //items[i].style.display = 'none';
                    items[i].classList.add('ocultar');
                }
                var cargarMasModulo = document.getElementById("cargarMasModulo");
                //cargarMasModulo.style.display = "block";
                cargarMasModulo.classList.remove('ocultar');
                var items2 = document.querySelectorAll('.itemPortafolio:not([category="' + categoria + '"])');
                for (var i = 0; i < items2.length; i++) {
                    //items2[i].style.display = 'none';
                    items2[i].classList.add('ocultar');
                }
                var items3 = document.querySelectorAll(`.itemPortafolio[category="${categoria}"]`);
                for (var i = 0; i < items3.length; i++) {
                    if (i < 3) {
                        //items3[i].style.display = 'block';
                        items3[i].classList.remove('ocultar');
                    }
                }
                if (categoria === "Todos") {
                    for (var i = 0; i < items.length; i++) {
                        if (i < 3) {
                            //items[i].style.display = 'block';
                            items[i].classList.remove('ocultar');
                        }
                    }
                } else {
                    var hiddenModulos2 = document.querySelectorAll(`.modulo-mas[category="${categoria}"].ocultar`);
                    if (hiddenModulos2.length === 0) {
                        cargarMasModulo.classList.add('ocultar');
                    }
                }
            })
        })
    }, 1000);

    function mas() {
        var cargarMasModulo = document.getElementById("cargarMasModulo");
        var categoryMain2 = document.querySelector('.categoria li.active').getAttribute("category");

        if (categoryMain2 === "Todos") {
            var hiddenModulos = document.querySelectorAll('.modulo-mas.ocultar');
            for (var i = 0; i < 3 && i < hiddenModulos.length; i++) {
                //hiddenModulos[i].style.display = 'block';
                hiddenModulos[i].classList.remove('ocultar');
            }

            if (document.querySelectorAll('.modulo-mas.ocultar').length === 0) {
                //cargarMasModulo.style.display = 'none';
                cargarMasModulo.classList.add('ocultar');
            }
        } else {
            var hiddenModulos = document.querySelectorAll(`.modulo-mas[category="${categoryMain2}"].ocultar`);
            for (var i = 0; i < 3 && i < hiddenModulos.length; i++) {
                //hiddenModulos[i].style.display = 'block';
                hiddenModulos[i].classList.remove('ocultar');
            }
            //cargarMasModulo.style.display = 'none';
            cargarMasModulo.classList.add('ocultar');
        }
    }

    /*

    setTimeout(() => {
        var h2Element = document.querySelectorAll('.portafolio h2');

        for (let i = 0; i < h2Element.length; i++) {
            var textoH2 = h2Element[i].textContent;
            var palabras = textoH2.split(' ');
            var nuevoContenido = '';
            palabras.forEach(function (palabra) {
                h2Element[i].classList.add('mostrar');
                nuevoContenido += '<span>' + palabra + '</span> ';
            });
            h2Element[i].innerHTML = nuevoContenido.trim();
        }
    }, 100);

    const targetRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Umbral de visibilidad del 50%
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        var h2spanElement = document.querySelectorAll('.portafolio h2 span');
                        var tiempoTransicion = 70;
                        for (let i = 0; i < h2spanElement.length; i++) {
                            setTimeout(() => {
                                h2spanElement[i].classList.add('mostrar');
                            }, i * tiempoTransicion);
                        }
                    }, 100);

                    setTimeout(() => {
                        var h2activo = document.querySelectorAll('.portafolio h2');
                        for (let i = 0; i < h2activo.length; i++) {
                            h2activo[i].classList.add("active");
                        }
                    }, 1000);
                }
            });
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    */

    return <>
        <section className="portafolio" id='portafolio'>
            <div className='container'>
                <h2><FormattedMessage id="portafolio_title" /></h2>
                <div className='categoria'>
                    <ul>
                        <li className='active' category='Todos' onClick={change} ><FormattedMessage id="portafolio_cat_1" /></li>
                        <li category='Branding' onClick={change} ><FormattedMessage id="portafolio_cat_2" /></li>
                        <li category='Desarrollo web' onClick={change} >Development</li>
                    </ul>
                </div>
                <div className='itemsPortafolio'>
                    <div className='itemPortafolio modulo-mas' category='Branding'>
                        <img className='touch' src={touch} alt="click" />
                        <div>
                            <img src={lamar} alt="lamar" />
                            <div className='filtro'></div>
                        </div>
                        <h3>Consorcio Lamar</h3>
                        <p><FormattedMessage id="portafolio_nombre_categoria_branding" /></p>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Desarrollo web'>
                        <img className='touch' src={touch} alt="click" />
                        <div>
                            <img src={vet} alt="vet" />
                            <div className='filtro'></div>
                        </div>
                        <h3>Veterinaria San Marcos</h3>
                        <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Branding'>
                        <img className='touch' src={touch} alt="click" />
                        <div>
                            <img src={chavin} alt="img5" />
                            <div className='filtro'></div>
                        </div>
                        <h3>AG - Chavin</h3>
                        <p><FormattedMessage id="portafolio_nombre_categoria_branding" /></p>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Desarrollo web'>
                        <img className='touch' src={touch} alt="click" />
                        <div>
                            <img src={lamarWeb} alt="img5" />
                            <div className='filtro'></div>
                        </div>
                        <h3>Consorcio Lamar</h3>
                        <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Desarrollo web'>
                        <img className='touch' src={touch} alt="click" />
                        <div>
                            <img src={provetsur} alt="provetsur" />
                            <div className='filtro'></div>
                        </div>
                        <h3>Provetsur</h3>
                        <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Desarrollo web'>
                        <img className='touch' src={touch} alt="click" />
                        <div>
                            <img src={xinxingWeb} alt="xinxingWeb" />
                            <div className='filtro'></div>
                        </div>
                        <h3>XinXing</h3>
                        <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Desarrollo web'>
                        <img className='touch' src={touch} alt="click" />
                        <div>
                            <img src={nerd} alt="nerd" />
                            <div className='filtro'></div>
                        </div>
                        <h3>NERD - Digital Minds</h3>
                        <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Desarrollo web'>
                        <img className='touch' src={touch} alt="click" />
                        <div>
                            <img src={wedding} alt="wedding" />
                            <div className='filtro'></div>
                        </div>
                        <h3>Wedding Video Studio</h3>
                        <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                    </div>
                </div>
                <div className='botones'>
                    <button className='mas' id='cargarMasModulo' onClick={mas}>Ver más proyectos</button>
                </div>
            </div>
            <Swiper
                category='Todos'
                paceBetween={60}
                slidesPerView={3.3}
                autoplay={{ delay: 3000, disableOnInteraction: false, }}
                loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                breakpoints={{
                    1700: {
                        spaceBetween: 60,
                        slidesPerView: 3.3
                    },
                    1441: {
                        spaceBetween: 50,
                        slidesPerView: 3.3
                    },
                    1387: {
                        spaceBetween: 50,
                        slidesPerView: 3.3
                    },
                    1315: {
                        spaceBetween: 40,
                        slidesPerView: 3.05
                    },
                    1129: {
                        spaceBetween: 40,
                        slidesPerView: 2.6
                    },
                    1001: {
                        spaceBetween: 40,
                        slidesPerView: 2.5
                    },
                    934: {
                        spaceBetween: 40,
                        slidesPerView: 2.2
                    },
                    738: {
                        spaceBetween: 40,
                        slidesPerView: 1.8
                    },
                    636: {
                        spaceBetween: 40,
                        slidesPerView: 1.5
                    },
                    531: {
                        spaceBetween: 40,
                        slidesPerView: 1.3
                    },
                    501: {
                        spaceBetween: 40,
                        slidesPerView: 1.2
                    }
                }}
            >
                <SwiperSlide>
                    <div>
                        <img src={lamar} alt="lamar" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Consorcio Lamar</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_branding" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={vet} alt="vet" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Veterinaria San Marcos</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={chavin} alt="img5" />
                        <div className='filtro'></div>
                    </div>
                    <h3>AG - Chavin</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_branding" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={lamarWeb} alt="img5" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Consorcio Lamar</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={provetsur} alt="provetsur" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Provetsur</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={xinxingWeb} alt="xinxingWeb" />
                        <div className='filtro'></div>
                    </div>
                    <h3>XinXing</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={nerd} alt="nerd" />
                        <div className='filtro'></div>
                    </div>
                    <h3>NERD - Digital Minds</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={wedding} alt="wedding" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Wedding Video Studio</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
            </Swiper>
            <Swiper
                category='Desarrollo web'
                className='ocultar'
                spaceBetween={60}
                slidesPerView={3.3}
                autoplay={{ delay: 3000, disableOnInteraction: false, }}
                loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                breakpoints={{
                    1700: {
                        spaceBetween: 60,
                        slidesPerView: 3.3
                    },
                    1441: {
                        spaceBetween: 50,
                        slidesPerView: 3.3
                    },
                    1387: {
                        spaceBetween: 50,
                        slidesPerView: 3.3
                    },
                    1315: {
                        spaceBetween: 40,
                        slidesPerView: 3.05
                    },
                    1129: {
                        spaceBetween: 40,
                        slidesPerView: 2.6
                    },
                    1001: {
                        spaceBetween: 40,
                        slidesPerView: 2.5
                    },
                    934: {
                        spaceBetween: 40,
                        slidesPerView: 2.2
                    },
                    738: {
                        spaceBetween: 40,
                        slidesPerView: 1.8
                    },
                    636: {
                        spaceBetween: 40,
                        slidesPerView: 1.5
                    },
                    531: {
                        spaceBetween: 40,
                        slidesPerView: 1.3
                    },
                    501: {
                        spaceBetween: 40,
                        slidesPerView: 1.2
                    }
                }}
            >
                <SwiperSlide>
                    <div>
                        <img src={vet} alt="vet" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Veterinaria San Marcos</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={lamarWeb} alt="img5" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Consorcio Lamar</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={provetsur} alt="provetsur" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Provetsur</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={xinxingWeb} alt="xinxingWeb" />
                        <div className='filtro'></div>
                    </div>
                    <h3>XinXing</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={nerd} alt="nerd" />
                        <div className='filtro'></div>
                    </div>
                    <h3>NERD - Digital Minds</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={wedding} alt="wedding" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Wedding Video Studio</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={vet} alt="vet" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Veterinaria San Marcos</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={lamarWeb} alt="img5" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Consorcio Lamar</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={provetsur} alt="provetsur" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Provetsur</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={xinxingWeb} alt="xinxingWeb" />
                        <div className='filtro'></div>
                    </div>
                    <h3>XinXing</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={nerd} alt="nerd" />
                        <div className='filtro'></div>
                    </div>
                    <h3>NERD - Digital Minds</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={wedding} alt="wedding" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Wedding Video Studio</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_web" /></p>
                </SwiperSlide>
            </Swiper>
            <Swiper
                category='Branding'
                className='ocultar'
                spaceBetween={60}
                slidesPerView={3.3}
                autoplay={{ delay: 3000, disableOnInteraction: false, }}
                loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                breakpoints={{
                    1700: {
                        spaceBetween: 60,
                        slidesPerView: 3.3
                    },
                    1441: {
                        spaceBetween: 50,
                        slidesPerView: 3.3
                    },
                    1387: {
                        spaceBetween: 50,
                        slidesPerView: 3.3
                    },
                    1315: {
                        spaceBetween: 40,
                        slidesPerView: 3.05
                    },
                    1129: {
                        spaceBetween: 40,
                        slidesPerView: 2.6
                    },
                    1001: {
                        spaceBetween: 40,
                        slidesPerView: 2.5
                    },
                    934: {
                        spaceBetween: 40,
                        slidesPerView: 2.2
                    },
                    738: {
                        spaceBetween: 40,
                        slidesPerView: 1.8
                    },
                    636: {
                        spaceBetween: 40,
                        slidesPerView: 1.5
                    },
                    531: {
                        spaceBetween: 40,
                        slidesPerView: 1.3
                    },
                    501: {
                        spaceBetween: 40,
                        slidesPerView: 1.2
                    }
                }}
            >
                <SwiperSlide>
                    <div>
                        <img src={lamar} alt="lamar" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Consorcio Lamar</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_branding" /></p>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src={chavin} alt="img5" />
                        <div className='filtro'></div>
                    </div>
                    <h3>AG - Chavin</h3>
                    <p><FormattedMessage id="portafolio_nombre_categoria_branding" /></p>
                </SwiperSlide>
            </Swiper>
        </section>
    </>
};

export default Portafolio;