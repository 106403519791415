import React from 'react';
import facebook from '../icons/facebook.svg'
import instagram from '../icons/instagram.svg'
import whatsapp from '../icons/whatsapp.svg'
import atenas from '../icons/logo-atenas.svg'
import nerd from "../icons/logonerd.svg";
import corazon from '../icons/corazon.svg'
import { FormattedMessage } from 'react-intl'

const Pie = () => {
    return <>
        <section className='pie'>
            <div className='container'>
                <div className='part1'>
                    <p>Built with ❤ 2023</p>
                </div>
                <div className='part3'>
                    <p>Follow us: <b><a href='https://www.instagram.com/nerd_studiodesign/' target='_blank'>Instagram</a> & <a href='https://www.linkedin.com/company/nerdmedia' target='_blank'>Linkedin</a></b></p>
                </div>
            </div>
        </section>
    </>
}

export default Pie;