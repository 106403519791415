import './sass/style.scss'
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Nosotros from './components/Nosotros';
import Portafolio from './components/Portafolio';
import Servicios from './components/Servicios'
import Banner from './components/Banner';
import Clientes from './components/Clientes';
import Equipo from './components/Equipo';
import Testimonios from './components/Testimonios';
import Contacto from './components/Contacto';
import AOS from 'aos';
import './css/aos.css'
import Pie from './components/Pie';
import { useEffect, useContext } from 'react';
import { langContext } from './context/langContext'

function App() {
  const idioma = useContext(langContext);
  console.log(idioma);
  useEffect(() => {
    AOS.init();
  })
  // Función para verificar si es un dispositivo iPad
  function isiPad() {
    return navigator.userAgent.match(/iPad/i) !== null;
  }

  // Función para verificar si es un dispositivo iPhone
  function isiPhone() {
    return (
      navigator.userAgent.match(/iPhone/i) !== null ||
      navigator.userAgent.match(/iPod/i) !== null
    );
  }

  // Ejemplo de uso
  if (isiPad()) {
    document.querySelector("body").classList.add("ipad")
  } else if (isiPhone()) {
    document.querySelector("body").classList.add("iphone")
  } else {
  }
  return (
    <div>
      <Navbar />
      <main>
        <Home />
        <Portafolio />
        <Servicios />
        <Nosotros />
        <Equipo />
        <Contacto />
        <Pie />
      </main>
    </div>
  );
}

export default App;
